.mcq-container {
    max-height: 90vh;
    overflow-y: auto;
    overflow-x: hidden;
    border: 1px solid #ccc;
    border-radius: 8px;
    padding: 20px;
    background-color: #f9f9f9;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  }
  
  .question-box {
    word-wrap: break-word;
    white-space: pre-wrap;
    overflow-wrap: break-word;
    margin-bottom: 20px;
    border: 1px solid #ddd;
    padding: 15px;
    border-radius: 8px;
    background-color: #ffffff;
  }
  
  .option-box {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    word-wrap: break-word;
    white-space: pre-wrap;
    margin-bottom: 10px;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    background-color: #f5f5f5;
  }
  
  .option-box.correct {
    background-color: #d4edda;
    border-color: #c3e6cb;
  }
  
  img {
    max-width: 100%;
    height: auto;
    display: block;
    margin: 10px 0;
  }
  
  h3 {
    margin-bottom: 15px;
  }
  .match-container {
    margin-top: 10px;
  }
  
  .match-table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .match-table th,
  .match-table td {
    padding: 8px;
    border: 1px solid #ddd;
    text-align: left;
  }
  
  .match-table th {
    background-color: #f4f4f4;
  }
  
  .match-list {
    list-style-type: decimal;
    padding-left: 20px;
  }
  
  .match-list li {
    margin: 5px 0;
  }
  