.main {
  text-align: center;
  justify-content: center;
  display: flex;
  align-items: center;
  height: 100vh;
  background-image: url('https://images.unsplash.com/photo-1509475826633-fed577a2c71b?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8cXVlc3Rpb24lMjBwYXBlcnxlbnwwfHwwfHx8MA%3D%3D'); /* Set the path to your background image */
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-sizing: border-box;
  padding: 0 20px;
  position: relative;
}

.main::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.4); 
  border-radius: 20px;
}

.sub-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 25%;
  min-width: 320px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
  padding: 30px 20px;
  border-radius: 20px;
  background-color: #fff;
  z-index: 1; 
}




.imgs {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
}

.container-image {
  background-color: #f0f0f0;
  border-radius: 50%;
  height: 100px;
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.profile {
  height: 90px;
  width: 90px;
  border-radius: 50%;
}

h1 {
  font-size: 1.8rem;
  margin-bottom: 20px;
  color: #333;
}

.email {
  height: 20px;
  width: 20px;
  position: absolute;
  margin-left: 10px;
  margin-top: 12px;
}

.name {
  padding-left: 40px;
  font-size: 16px;
  width: 100%;
  height: 45px;
  border-radius: 10px;
  border: 1px solid #ccc;
  margin-bottom: 20px;
  outline: none;
  transition: border 0.3s ease-in-out;
}

.name:focus {
  border: 1px solid #86ec6d;
}

.second-input {
  margin-top: 10px;
}

button {
  width: 50%;
  height: 50px;
  border-radius: 25px;
  background-color: #86ec6d;
  color: white;
  font-size: 18px;
  border: none;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-left: 50px;
}

button:hover {
  background-color: #69d556;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.login-button {
  margin-top: 15px;
  width: 100%;
}

.link {
  font-size: 16px;
  font-weight: 400;
  margin-top: 20px;
}

a {
  color: blue;
  text-decoration: none;
  font-weight: 500;
}

a:hover {
  text-decoration: underline;
}

/* Responsive Styling */
@media (max-width: 768px) {
  .sub-main {
      width: 80%;
  }

  h1 {
      font-size: 1.5rem;
  }

  button {
      font-size: 16px;
  }
}

@media (max-width: 480px) {
  .name {
      font-size: 14px;
      padding-left: 35px;
  }

  button {
      font-size: 14px;
      height: 45px;
  }

  h1 {
      font-size: 1.2rem;
  }
}

@media (max-width: 768px) {
  .main {
      background-image: url('https://images.unsplash.com/photo-1509475826633-fed577a2c71b?w=800&auto=format&fit=crop&q=60&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxzZWFyY2h8N3x8cXVlc3Rpb24lMjBwYXBlcnxlbnwwfHwwfHx8MA%3D%3D');
  }
}
