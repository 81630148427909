
.dashboard-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    /* padding: 20px; */
    height: calc(100vh - 40px); 
    justify-content: space-between;
    overflow: hidden; 
  }
  
  .upload-section {
    flex: 1;
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    overflow: hidden;
  }
  
  .upload-header {
    font-size: 1.5rem;
    font-weight: 600;
  }
  
  .upload-actions {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .file-input {
    margin-bottom: 15px;
    /* margin-left: auto; */
  }
  
  .preview-frame-container {
    flex-grow: 1;
    overflow-y: auto; 
    max-height: 100%; 
    display: flex;
    justify-content: center;
  }
  
  .preview-frame {
    width: 100%;
    max-height: 100%; 
    border: none;
    border-radius: 8px;
    overflow-y: auto; 
  }
  
  .loading-section {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;
  }
  
  .mcq-section {
    flex: 1;
    background-color: #fff;
    border-radius: 8px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    height: 100%;
    overflow-y: auto;
  }
  
  .error-message {
    color: red;
    font-weight: bold;
    text-align: center;
  }
  
  @media screen and (max-width: 768px) {
    .dashboard-container {
      flex-direction: column;
    }
  }
  